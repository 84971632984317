<template>
	<div class="auth-box">


    <van-loading type="spinner" color="#1989fa">登陆中...</van-loading>
	</div>
</template>

<script>
	import {getAppToken} from "../../api/auth";
	import {mapMutations} from 'vuex'
	import {SET_TOKEN,SET_USER,SET_ROLE} from '../../const.js'
	import store from '../../store'
	export default {
		data() {
			return {
				url:'',
        page:''
			};
		},
		methods: {
			...mapMutations([SET_TOKEN,SET_USER,SET_ROLE]),
			async login( paramsUrl) {
				console.info("auth login params:",paramsUrl)
				this.url = 'wxmp/auth/login_by_wxmp'+paramsUrl
				let r = await getAppToken(this.url,null)
				  if(r.errno!=0){
					  Notify({ type: 'warning', message: r.msg });
				  }else{
					  this.setToken(r.data.token)
            this.setRole(r.data.role)
					  this.setUser(JSON.stringify(r.data.userInfo))

            let page = this.getQueryVariable("page");
            page =page.replace("%2F",'/').replace("%2F",'/')
            page = page+"?pid="+this.getQueryVariable("pid")+"&back="+this.getQueryVariable("back")
            console.info(page,window.location.search.substring(1))
            this.page=page+"   ||   "+window.location.search.substring(1)
            if (page.indexOf("select")>-1){//如果要跳转到选择注册身份的页面
              if (r.data.userInfo.phone){//如果有手机号就跳转到首页
                this.$router.push("home")
              }else {
                this.$router.push(page)
              }
            }else {
              this.$router.push(page)
            }

				  }
			},
      getQueryVariable(variable)
      {
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i=0;i<vars.length;i++) {
          var pair = vars[i].split("=");
          if(pair[0] == variable){return pair[1];}
        }
        return("");
      }
		},
		mounted() {
      console.info("========auth=======",`${store.state.token}`)
      if (window.performance.navigation.type!=2){
        this.login(window.location.search)
      }else{
        window.close();
        console.info("========auth=======关闭页面=======")
      }
			// setTimeout(() => {
			//
			// }, 50)
		}
	}
</script>

<style lang="less" scoped>
	.auth-box{
		padding: 60px;
		text-align: center;
		background: #fff;
		font-size: 25px;
		height: 1000px;
	}
</style>
