import axios from '../http'

/**
 * 获取字典
 * @param {code} data 
 * @returns 
 */
export function getAppToken(url, data) {
    return axios.get(url, data)
}

export function getTicket(data) {
    return axios.get("wxmp/auth/ticket?page="+data.page, data)
}